<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Start Journey</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleStartJourney" v-slot="{ errors }">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Journey</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.journeys }">
                                    <Field autocomplete="off" name="journeys" v-model="form.journey_ids" rules="required">
                                        <multiselect
                                            v-model="form.journey_ids"
                                            label="name"
                                            value-prop="id"
                                            :searchable="true"
                                            :options="journeys"
                                            placeholder="Select Journey"
                                            mode="tags"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="journeys" class="text-danger" />
                            </div>
                        </div>
                        <div v-show="form.journey_ids.length == 1">
                            <label for="steps" class="switch_option capsule_btn">
                                <h5>Start at first step?</h5>
                                <input type="checkbox" id="steps" v-model="form.start_first_action" :true-value="1" :false-value="0" hidden>
                                <div><span></span></div>
                            </label>
                            <div class="form_grp" v-if="form.start_first_action == 0">
                                <div class="group_item">
                                    <label class="input_label">Assign Step</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.action }">
                                        <Field autocomplete="off" name="action" v-model="action_step" rules="required">
                                            <multiselect
                                                v-model="action_step"
                                                label="title"
                                                :searchable="true"
                                                value-prop="step"
                                                :options="actions"
                                                placeholder="Select Action"
                                            ></multiselect>
                                        </Field>
                                    </div>
                                    <ErrorMessage name="action" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <div v-show="form.journey_ids.length">
                            <schedule-component :schedule-data="form" title="journey" :close-modal="closeModal" />
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="journeyLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="journeyLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="journeyLoader"></i>{{ journeyLoader ? ' Starting' : 'Start' }}</button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex';

    const ScheduleComponent = defineAsyncComponent(() => import('@/components/Schedule'))

    export default {
        name: 'StartJourney',

        data () {
            return {
                form: {
                    contact_id: '',
                    journey_ids: [],
                    schedule_action: '/contacts/start-journey',
                    type: 'leads',
                    start_first_action: 1,
                    action: {
                        code: '',
                        step: '',
                        title: '',
                    },
                },
                action_step: [],
                actions: [],
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        watch: {
            'form.journey_ids' (ids) {
                const vm = this;

                if (ids.length == 1 ) {
                    let journey = vm.journeys.find( ({ id }) => id === ids[0] );

                    vm.action_step  = [];
                    vm.actions      = journey.actions;
                } else {
                    vm.form.action = [];
                }
            },

            action_step (actionStep) {
                const vm = this;

                let journey = vm.actions ? vm.actions.find(({ step }) => step === actionStep) : null;

                vm.form.action = journey ? journey : [];
            },

            modelValue (value) {
                const vm = this;

                if (value == true) {
                    vm.form.contact_id = vm.contacts.join(',');
                    vm.form.contacts   = vm.contacts;
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                if (vm.journeys.length == 0) {
                    vm.getAllJourneys({ select: 'id,name,action,action_title,action_type' });
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            ScheduleComponent
        },

        computed: {
            ...mapState({
                journeys: state => state.journeyModule.allJourneys,
                journeyLoader: state => state.journeyModule.journeyLoader,
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getAllJourneys: 'journeyModule/getAllJourneys',
                startJourney: 'journeyModule/startJourney',
            }),

            closeModal () {
                const vm = this;

                vm.resetForm();
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    contact_id: '',
                    journey_ids: [],
                    schedule_action: '/contacts/start-journey',
                    type: 'leads',
                    start_first_action: 1,
                    action: {
                        code: '',
                        step: '',
                        title: '',
                    },
                };
            },

            handleStartJourney (params, { setFieldError }) {
                const vm = this;

                vm.form.setFieldError = setFieldError;
                vm.form.closeModal = vm.closeModal;

                vm.startJourney(vm.form).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>
